import React from "react";
import Header from "../../componentes/header";
import Banner from "../../componentes/banner";
import Calendario from "../../componentes/calendario";
import Faq from "../../componentes/faq";
import Painel from "../../componentes/painel";
import Insta from "../../componentes/insta";
import Footer from "../../componentes/footer";
import Whats  from "./whatsapp-icon-square.svg"




function Home() {
    return (
        <>
            <Header />
            <Banner />
            <Calendario />
            <Faq />
            <Painel/>
            <Insta/>
            <Footer />
            <a href="https://api.whatsapp.com/send/?phone=5537988423461&text&app_absent=0" className="btn-whats"><img alt="whatsapp icone" src={Whats} itemprop="image" /></a>
        </>

    )
}
export default Home;